@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "inter-tight-fallback";
    size-adjust: 90%;
    src: local("Arial");
  }
}

@layer components {
  /* Portal Custom Classes */

  .portal-template-title {
    @apply font-['Space_Grotesk']	leading-[1.9rem] text-[1.5rem] font-medium;
  }

  .portal-template-thumbnail-title {
    @apply font-['Space_Grotesk']	leading-[1.6rem] text-[1.125rem] font-medium;
  }

  .portal-template-paragraph {
    @apply font-['Space_Grotesk']	leading-[0.95rem] text-[0.75rem] font-medium;
  }

  .portal-template-text-block {
    @apply [&_h1]:portal-template-title [&_h1]:my-2;
    @apply [&_h2]:portal-template-title [&_h2]:my-2;
    @apply [&_h3]:portal-template-title [&_h3]:my-2;
    @apply [&_h4]:portal-template-title [&_h4]:my-2;
    @apply [&_h5]:portal-template-title [&_h5]:my-2;
    @apply [&_h6]:portal-template-title [&_h6]:my-2;
    @apply [&_h7]:portal-template-title [&_h7]:my-2;

    @apply [&_p]:portal-template-paragraph;
  }

  /* MySpace Custom Classes */

  .my-space-template-title {
    @apply font-['Space_Mono'] text-[0.8rem] font-bold;
  }

  .my-space-template-paragraph {
    @apply font-['Space_Mono'] text-[0.8rem] font-normal;
  }

  .myspace-template-text-block {
    @apply [&_h1]:my-space-template-title [&_h1]:my-2;
    @apply [&_h2]:my-space-template-title [&_h2]:my-2;
    @apply [&_h3]:my-space-template-title [&_h3]:my-2;
    @apply [&_h4]:my-space-template-title [&_h4]:my-2;
    @apply [&_h5]:my-space-template-title [&_h5]:my-2;
    @apply [&_h6]:my-space-template-title [&_h6]:my-2;
    @apply [&_h7]:my-space-template-title [&_h7]:my-2;

    @apply [&_p]:my-space-template-paragraph;
  }

  .content-reveal-container {
    @apply absolute bottom-20 -translate-x-1/2 left-1/2 w-[80%] h-[70%] p-5 overflow-hidden;
  }

  .content-reveal-title {
    @apply font-interTight text-[2.5rem] leading-[2.313rem] uppercase break-words;
  }

  .content-reveal-paragraph {
    @apply font-interTight text-[0.813rem] leading-[0.983rem];
  }

  .content-reveal-price {
    @apply font-interTight text-[1.rem] leading-[2.188rem];
  }

  .content-reveal-block-link-button,
  .content-reveal-block-link-button[type="submit"] {
    @apply text-[1rem] rounded-full uppercase px-3 py-1 shrink-0;
  }

  .content-reveal-text-block {
    @apply [&_h1]:content-reveal-title [&_h1]:my-2;
    @apply [&_h2]:content-reveal-title [&_h2]:my-2;
    @apply [&_h3]:content-reveal-title [&_h3]:my-2;
    @apply [&_h4]:content-reveal-title [&_h4]:my-2;
    @apply [&_h5]:content-reveal-title [&_h5]:my-2;
    @apply [&_h6]:content-reveal-title [&_h6]:my-2;
    @apply [&_h7]:content-reveal-title [&_h7]:my-2;

    @apply [&_p]:content-reveal-paragraph;
  }

  .gaming-title {
    @apply font-ehsmb font-normal text-[1.375rem] leading-[1.399rem] uppercase break-words;
  }

  .gaming-paragraph {
    @apply font-ehsmb text-[0.938rem] leading-[1.25rem];
  }

  .gaming-text-block {
    @apply [&_h1]:gaming-title [&_h1]:my-2;
    @apply [&_h2]:gaming-title [&_h2]:my-2;
    @apply [&_h3]:gaming-title [&_h3]:my-2;
    @apply [&_h4]:gaming-title [&_h4]:my-2;
    @apply [&_h5]:gaming-title [&_h5]:my-2;
    @apply [&_h6]:gaming-title [&_h6]:my-2;
    @apply [&_h7]:gaming-title [&_h7]:my-2;

    @apply [&_p]:gaming-paragraph;
  }

  .gaming-carousel-button {
    @apply absolute bg-transparent border-none cursor-pointer text-gaming-pink;
  }

  .gaming-single-tweet-block .react-tweet-theme {
    @apply my-0;
  }

  /* Temporary solution to reset react-phone-input-2 without affecting checkout pages */
  .react-tel-input {
    /* Default classes */
    @apply inline-flex items-center w-full h-11 border border-neutral-200 hover:border-neutral-300 rounded-sm bg-white hover:bg-white;

    /* Disabled classes */
    @apply disabled:cursor-not-allowed disabled:border-neutral-400 disabled:bg-neutral-50 disabled:text-neutral-400 disabled:pointer-events-none;

    /* Animation classes */
    @apply transition duration-75 ease-out hover:ease-in;
  }

  .form-control {
    @apply text-black !text-2xs !bg-white/0 !border-none !outline-none !rounded-md !w-full !h-full !min-w-0 !appearance-none !pl-14 !tracking-normal;
    @apply disabled:cursor-not-allowed disabled:text-neutral-500;
    @apply placeholder-neutral-500;
  }

  .flag-dropdown.open .selected-flag {
    @apply !bg-neutral-100;
  }

  .flag-dropdown {
    @apply !bg-white/0 !border-0 flex items-center;
  }

  .selected-flag {
    @apply !bg-white/0 !pl-3 !w-12 !h-5 !border-r !border-neutral-300;
  }

  .country-list {
    @apply !bg-white !rounded-lg !shadow-sm;
  }

  .country {
    @apply hover:!bg-neutral-50;
  }

  .dial-code {
    float: right;
  }

  .country.highlight,
  .country.active {
    @apply !bg-neutral-50;
  }

  .default-template-container {
    @apply w-full mx-auto text-center flex flex-col grow shrink-0 basis-auto px-3 relative max-w-full md:max-w-2xl;
  }

  .default-template-title {
    @apply font-default-template-heading text-[1.063rem] leading-5 font-normal;
  }

  .default-template-paragraph {
    @apply font-default-template-body text-[0.75rem] leading-4 font-normal;
  }

  .default-text-block {
    @apply [&_h1]:default-template-title [&_h1]:my-2 [&_h1]:text-[inherit];
    @apply [&_h2]:default-template-title [&_h2]:my-2 [&_h2]:text-[inherit];
    @apply [&_h3]:default-template-title [&_h3]:my-2 [&_h3]:text-[inherit];
    @apply [&_h4]:default-template-title [&_h4]:my-2 [&_h4]:text-[inherit];
    @apply [&_h5]:default-template-title [&_h5]:my-2 [&_h5]:text-[inherit];
    @apply [&_h6]:default-template-title [&_h6]:my-2 [&_h6]:text-[inherit];
    @apply [&_h7]:default-template-title [&_h7]:my-2 [&_h7]:text-[inherit];

    @apply [&_p]:default-template-paragraph;
  }

  .default-carousel-arrow-top {
    top: calc(50% - 1.375rem);
  }

  .default-youtube-player {
    iframe {
      @apply absolute top-0 left-0 w-full h-full;
    }
  }
}
