@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .timezone-picker-dropdown {
    width: var(--radix-popover-trigger-width);
    max-height: var(--radix-popover-content-available-height);
  }
}
