.slide-enter {
  opacity: 0;
}

.slide-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

.slide-exit {
  opacity: 1;
}

.slide-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

mux-player {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  aspect-ratio: 1;

  --seek-backward-button: none;
  --seek-forward-button: none;
}

#hubspot-messages-iframe-container {
  z-index: 9999990;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  /* background: ${Colors.mangoFireOrangeGradient}; */
  @apply bg-cherry-300;
  position: fixed;
  z-index: 9999999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

#nprogress .peg {
  @apply shadow-lg border-cherry-300;
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 999999999;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  @apply border-cherry-300;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.shepherd-header {
  background: #ffffff !important;
  padding: 10px 16px !important;
}

.shepherd-title {
  font-weight: 600;
  font-size: 18px;
}

.shepherd-button {
  background-color: #464b56 !important;
}

.shepherd-button:hover {
  background-color: #7791c7 !important;
  color: #ffffff !important;
}

.shepherd-button-secondary {
  background-color: #ffffff !important;
  border: 1px solid #a4a1a0;
  color: #000000;
}

.shepherd-button-secondary:hover {
  background-color: rgb(242 239 235 / var(--tw-bg-opacity)) !important;
  color: rgba(0, 0, 0, 0.75) !important;
}

.shepherd-text {
  padding: 16px !important;
  padding-top: 0 !important;
}
