@import "./styles/third-party-styles.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply font-regular;
  }

  h1 {
    @apply text-3xl;
  }

  h2 {
    @apply text-2xl;
  }

  h3 {
    @apply text-xl;
  }

  h4 {
    @apply text-lg;
  }

  h5 {
    @apply text-sm;
  }

  h6 {
    @apply text-xs;
  }

  body {
    @apply text-base;
    @apply text-neutral-800;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-neutral-1000;
  }

  h1 {
    @apply text-3xl;
  }

  h2 {
    @apply text-2xl;
  }

  h3 {
    @apply text-xl;
  }

  h4 {
    @apply text-lg;
  }

  h5 {
    @apply text-sm;
  }

  h6 {
    @apply text-xs;
  }

  html,
  body,
  #__next {
    @apply h-full;
  }
}

@layer components {
  .card {
    background-color: theme("colors.white");
    border-radius: theme("borderRadius.sm");
    padding: theme("spacing.6");
    box-shadow: theme("boxShadow.sm");
  }

  .pricing-creator-image-card {
    position: relative;
    border: solid 5px transparent;
    border-radius: 30px;
    background: 50% / cover border-box padding-box;
    margin: 5px;
    height: 350px;
  }

  .pricing-creator-image-card:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    border: inherit;
    border-radius: 30px;
    border-color: transparent;
    background: inherit;
    background-clip: border-box;
    filter: blur(3px);
  }

  .vip-badge:after {
    content: "";
    width: 24px;
    height: 24px;
    margin-top: -4px;
    margin-left: 4px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='url(%23paint0_linear_21765_126674)' d='M12 1C5.939 1 1 5.928 1 12C1 18.061 5.939 23 12 23C18.072 23 23 18.061 23 12C23 6.03092 18.2376 1.16738 12.3077 1.00423L12 1Z'/%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M11.4411 15.3741C11.4382 15.377 11.4353 15.38 11.4323 15.3829C11.0387 15.7765 10.4005 15.7765 10.0069 15.3829L9.4628 14.8388L7.37139 12.7474C6.97777 12.3538 6.97777 11.7156 7.37139 11.3219C7.76501 10.9283 8.4032 10.9283 8.79682 11.3219L10.7218 13.2469L15.2833 8.68538C15.6763 8.29237 16.3135 8.29237 16.7066 8.68538C17.0996 9.07839 17.0996 9.71558 16.7066 10.1086L11.4411 15.3741Z' clip-rule='evenodd'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_21765_126674' x1='7.6' x2='21.936' y1='8.944' y2='10.462' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%234cb0f3'/%3E%3Cstop offset='1' stop-color='%234cb0f3'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
    display: inline-block;
    vertical-align: middle;
  }
}

@layer components {
  .container {
    @apply w-full max-w-2xl px-3.5 m-auto md:max-w-7xl md:px-6;
  }

  .settings-container {
    @apply w-full max-w-2xl px-3.5 py-12 m-auto md:max-w-3xl md:px-6;
  }

  .my-page-container {
    @apply w-full max-w-2xl pb-24 m-auto md:max-w-3xl;
  }

  .store-edit-product-container {
    @apply w-full max-w-2xl p-4 m-auto md:max-w-3xl;
  }

  .my-page-mobile-actions {
    @apply fixed md:hidden flex items-center justify-start gap-1 bottom-0 w-full p-5 bg-gradient-to-t from-black/30;
  }

  .loader-screen {
    @apply absolute w-full h-full flex items-center justify-center;
  }

  /* My Page Design Tab background styles section */
  .background-style-type {
    @apply absolute w-full h-full rounded-md border-2 border-white;
  }

  .design-section-container {
    @apply w-full max-w-screen-md p-4 md:p-8 m-auto relative;
  }

  .divider {
    @apply w-full h-px bg-black/10;
  }

  .v-divider {
    @apply h-auto w-px bg-black/10;
  }

  .my-page-link-form-container {
    @apply w-full max-w-2xl m-auto md:max-w-4xl;
  }

  .email-flows-table {
    @apply w-full min-w-max;

    thead tr {
      @apply text-neutral-600 text-2xs font-normal;
    }

    th {
      @apply font-normal;
    }

    tbody tr {
      @apply text-neutral-800;
    }

    td {
      @apply py-3;
    }

    td:first-of-type {
      @apply md:w-[600px];
    }

    th:not(:first-of-type),
    td:not(:first-of-type) {
      @apply px-4 md:px-0;
    }
  }

  .product-selector-dropdown-content {
    width: var(--radix-dropdown-menu-trigger-width);
  }
}

@layer utilities {
  .font-inherit {
    font-family: inherit;
  }

  .touch-callout-none {
    -webkit-touch-callout: none;
  }
}
